import { api_url } from "../env.js";

export const API_URL = {
    post: {
        login: api_url + '/login',
        addProperties: api_url + '/addproperties',
        editProperties:(propertyID) =>`${api_url}/editproperties/${propertyID}`,
        uploadPropertyImage: (propertyID) => `${api_url}/upload-image/${propertyID}`,
        deletePropertyImage: (propertyID, imageID) => `${api_url}/delete-image/${propertyID}/${imageID}`,
        massiveDeletePropertyImage: (propertyID) => `${api_url}/massive-delete-image/${propertyID}`,
        publishProperty: (propertyID) => `${api_url}/publish-property/${propertyID}`,
        postRegister: api_url + '/register',
        updateOrder: (propertyID) => `${api_url}/updateOrder/${propertyID}`,
        generateTempUrls: (propertyID) => `${api_url}/generate-temp-urls/${propertyID}`,
        storeMessage: api_url + '/postRegisterMessage',
        deleteMessages: api_url + '/deleteMessages',
        
    },
    get: {
        getPropertyImages: (propertyID) => `${api_url}/get-images/${propertyID}`,
        planes: api_url + '/plans',
        properties: api_url + '/properties',
        getEditProperty: (propertyID) => `${api_url}/editproperties/${propertyID}`,
        deleteProperties: (propertyID) => `${api_url}/deleteproperties/${propertyID}`,
        getLandingInmueble: (propertyID) => `${api_url}/properties/${propertyID}`,
        getPropertiesHomePage: api_url + '/propertiesHomePage',
        getMessages: (page,perPage,order) => api_url + `/messages?perPage=${perPage}&page=${page}&order=${order}`,
    }

}