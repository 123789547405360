import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LogoTypeHorizontal from '../../Assets/Img/NewIconEspacios-removebg-preview.png';
import { MdFormatListBulleted } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { IoIosNotifications } from "react-icons/io";
import { GoSignIn } from "react-icons/go";
import { TbWorld } from "react-icons/tb";

import './Navbar.css';

const Navbar = ({ isAuthenticated, userName, onLogout }) => {
    const [open, setOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [accountDropdownOpen, setAccountDropdownOpen] = useState(false);
    const [isResponsive, setIsResponsive] = useState(window.innerWidth <= 1024); // Cambiado a 1024px

    const responsiveLinks = [
        { name: "Ver inmuebles", link: "/opcion1" },
        { name: "Ver inmobiliarias", link: "/opcion2" },
        { name: "Mis inmuebles", link: "/opcion2" },
        { name: "Interesados", link: "/interesados" },
        { name: "Estadísticas", link: "/opcion2" },
        { name: "Membresía", link: "/opcion2" },
        { name: "Portal público", link: "/opcion3" },
        { name: "Configuración", link: "/opcion4" },
        { name: "Ayuda", link: "/opcion5" },
        { name: "Cerrar Sesión", link: "/opcion6" },
    ];

    useEffect(() => {
        const handleResize = () => {
            setIsResponsive(window.innerWidth <= 1024); // Cambiado a 1024px
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const guestLinks = [
        { name: "Iniciar sesión", icon: <GoSignIn className="mr-2" />, link: "/login" },
        { name: "Idioma", icon: <TbWorld className="mr-2" />, link: "/idiomas" },
    ];

    const userLinks = [
        { name: "Ver inmuebles", link: "/perfil" },
        { name: "Ver inmobiliarias", link: "/configuracion" },
    ];

    const additionalLinks = [
        { name: "Mis inmuebles", link: "/inmuebles" },
        { name: "Interesados", link: "/interesados" },
        { name: "Estadísticas", link: "/metricas" },
        { name: "Membresía", link: "/" },
    ];

    const handleDropdownToggle = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleAccountDropdownToggle = () => {
        setAccountDropdownOpen(!accountDropdownOpen);
    };

    return (
        <div>
            <div className="shadow-md w-full fixed top-0 left-0 z-50">
                <div className="md:flex items-center justify-between bg-white md:px-10 px-7 whitespace-nowrap md:py-2 lg:py-0 py-0 sm:py-2">
                    <div className="font-bold text-xl md:text-2xl cursor-pointer flex items-center font-[Montserrat] text-black">
                        <div className='text-2xl md:text-3xl mr-1'>
                            <Link to="/">
                                <img src={LogoTypeHorizontal} alt="Logo" className="object-contain max-w-[150px] md:max-w-[120px] lg:max-w-[230px]" />
                            </Link>
                        </div>
                    </div>
                    <div onClick={() => setOpen(!open)} className="text-2xl md:text-3xl absolute right-8 top-[20px] cursor-pointer lg:hidden">
                        <ion-icon name={open ? 'close' : 'menu'}></ion-icon>
                    </div>
                    <ul className={`lg:flex lg:items-center font-[Montserrat] text-base lg:text-lg lg:pb-0 pb-12 absolute lg:static bg-white lg:z-auto z-[-1]
                     left-0 w-full lg:w-auto lg:pl-0 pl-9 transition-all duration-500 ease-in ${open ? 'top-20 opacity-100' : 'top-[-490px]'} lg:opacity-100 opacity-0 whitespace-nowrap`}>
                        
                        {!isResponsive && isAuthenticated &&  (
                            <li className="lg:ml-4 text-base lg:text-lg lg:my-0 my-3 flex items-center px-2">
                                <Link to='/inmuebles' className="nav-link hover:text-Secondary_Color duration-500 flex items-center">
                                    Mis Inmuebles
                                </Link>
                                <Link to='/interesados' className="nav-link hover:text-Secondary_Color duration-500 flex items-center">
                                    Interesados
                                </Link>
                                <Link to='/metricas' className="nav-link hover:text-Secondary_Color duration-500 flex items-center">
                                    Estadísticas
                                </Link>

                                <li className={"relative lg:ml-4 text-base lg:text-lg lg:my-0 px-4 "} onMouseEnter={() => {setDropdownOpen(true)}} onMouseLeave={() => {setDropdownOpen(false)}}>
                                    <button className="flex items-center text-black hover:text-Secondary_Color duration-500">
                                        <span className="mr-2">Bolsa inmobiliaria</span>
                                        <ion-icon name={dropdownOpen ? 'chevron-up' : 'chevron-down'}></ion-icon>
                                    </button>
                                    <ul className={"absolute bg-white shadow-lg pt-2 right-0 w-48" + ` ${dropdownOpen ? "" : "hidden"}`}>
                                        {userLinks.map((link) => (
                                            <li key={link.name} className="flex items-center px-4 py-2 ">
                                                <Link to={link.link} className="flex items-center text-black hover:text-Secondary_Color duration-500">
                                                    {link.name}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </li>

                                <Link to='/' className="nav-link hover:text-black duration-500 flex items-center text-[#bb8b31] font-semibold">
                                    Planes
                                </Link>
                            </li>
                        )}
                        {!isResponsive && !isAuthenticated && (
                            <li className="lg:ml-4 text-base lg:text-lg lg:my-0 my-3 flex items-center">
                                <Link to='/login' className="nav-link text-black hover:text-Secondary_Color duration-500 flex items-center">
                                    <GoSignIn className="mr-2" /> Iniciar Sesión 
                                </Link>
                                <Link to='/idiomas' className="nav-link text-black hover:text-Secondary_Color duration-500 flex items-center">
                                    <TbWorld className="mr-2" /> Idioma 
                                </Link>
                            </li>
                        )}

                        {isResponsive && responsiveLinks.map((link) => (
                            <li key={link.name} className="lg:ml-4 text-base lg:text-lg lg:my-0 my-3 flex items-center">
                                <Link to={link.link} className="hover:text-Secondary_Color duration-500 flex items-center">
                                    {link.name}
                                </Link>
                            </li>
                        ))}
                        {!isResponsive && isAuthenticated && (
                            <li className="relative lg:ml-4 text-base lg:text-lg lg:my-0 my-3" onMouseEnter={() => {setAccountDropdownOpen(true)}} onMouseLeave={() => {setAccountDropdownOpen(false)}}>
                                <div className="my-3 flex items-center">
                                    <IoIosNotifications className="text-2xl cursor-pointer hover:text-Primary_Color transition-colors ml-12 duration-300" />
                                    <button className="flex items-center gap-2 border ml-6 border-gray rounded-full py-2 px-4">
                                        <MdFormatListBulleted />
                                        <div className="text-black rounded-full border border-gray overflow-hidden">
                                            <FaUser />
                                        </div>
                                        <div>{userName}</div>
                                    </button>
                                </div>
                                <ul className={"absolute bg-white shadow-lg pt-2 right-0 w-48" + ` ${accountDropdownOpen ? "" : "hidden"}`}>
                                    <li className="flex items-center px-4 py-2">
                                        <Link to="/account" className="flex items-center text-black hover:text-Secondary_Color duration-500">
                                            Portal público
                                        </Link>
                                    </li>
                                    <li className="flex items-center px-4 py-2">
                                        <Link to="/account" className="flex items-center text-black hover:text-Secondary_Color duration-500">
                                            Configuración
                                        </Link>
                                    </li>
                                    <li className="flex items-center px-4 py-2">
                                        <Link to="/account" className="flex items-center text-black hover:text-Secondary_Color duration-500">
                                            Ayuda
                                        </Link>
                                    </li>
                                    <li className="flex items-center px-4 py-2">
                                        <button onClick={onLogout} className="flex items-center duration-500">
                                            <Link to="/" className="flex items-center text-black hover:text-Secondary_Color duration-500">
                                                Cerrar Sesión
                                            </Link>
                                        </button>
                                    </li>
                                </ul>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Navbar;
