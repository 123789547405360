import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LogoTypeHorizontal from './Assets/Img/LogotypePositiveWhite.png';

// import { ByhlaContext } from './Context/ByhlaContext';




// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
  
//   // <ByhlaContext>
//   //   
//   // </ByhlaContext>

//   <App />
// );

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      width: '100vw',
      color: '#f8b945',
      fontSize: '2.5rem',
      fontWeight: 'bold',
    }}
  >
    <img src={LogoTypeHorizontal} style={{width: "35rem"}}/>
    <span>Próximamente</span>
  </div>
);
