import React, { useState } from 'react';
import { FaCalendarAlt } from 'react-icons/fa'; // Importa el ícono de calendario
import { Line } from 'react-chartjs-2'; // Importa el gráfico de líneas de react-chartjs-2
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
// Registra los componentes de Chart.js
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const Metricas = () => {
    const [selectedChart, setSelectedChart] = useState('Exposición'); // Estado para controlar qué gráfica mostrar

    // Datos para cada gráfico
    const Exposicion = {
        labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio'],
        datasets: [
            {
                label: 'Exposición',
                data: [30, 50, 40, 60, 70, 80, 90],
                borderColor: 'rgba(75, 192, 192, 1)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
            },
        ],
    };

    const Visualizacion = {
        labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio'],
        datasets: [
            {
                label: 'Visualizaciónes',
                data: [20, 10, 30, 50, 40, 35, 80],
                borderColor: 'rgba(153, 102, 255, 1)',
                backgroundColor: 'rgba(153, 102, 255, 0.2)',
            },
        ],
    };

    const Interaccion = {
        labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio'],
        datasets: [
            {
                label: 'Interesados',
                data: [10, 30, 20, 40, 100, 60, 20],
                borderColor: 'rgba(255, 159, 64, 1)',
                backgroundColor: 'rgba(255, 159, 64, 0.2)',
            },
        ],
    };

    // Renderiza el gráfico basado en el estado seleccionado
    const renderChart = () => {
        switch (selectedChart) {
            case 'Exposición':
                return <Line data={Exposicion} />;
            case 'Visualización':
                return <Line data={Visualizacion} />;
            case 'Interacción':
                return <Line data={Interaccion} />;
            default:
                return null;
        }
    };

    const selectChart = (chart) => {
        setSelectedChart(chart);
    };
    
    return (
        <div className="px-16 mb-8">
            <div className="mt-16 bg-Primary_Color_Light border border-gray px-4 py-4 rounded-lg">
                <p className="text-Secondary_Color text-lg font-semibold mb-2 border-b border-Secondary_Color">Estadísticas</p>
                <div className="flex items-center justify-left">
                    {/* Fecha Desde */}
                    <div className="flex items-center bg-Secondary_Color p-2 rounded-md">
                        <FaCalendarAlt className="text-white mr-2" /> {/* Icono de calendario */}
                        <input
                            type="date"
                            className="bg-transparent border-none text-white outline-none"
                            placeholder="Fecha Desde"
                        />
                    </div>

                    {/* Fecha Hasta */}
                    <div className="flex items-center bg-Secondary_Color p-2 rounded-md ml-4">
                        <FaCalendarAlt className="text-white mr-2" />
                        <input
                            type="date"
                            className="bg-transparent border-none text-white outline-none"
                            placeholder="Fecha Hasta"
                        />
                    </div>
                </div>
            </div>
            <div className='flex items-center justify-center space-x-64'>
                {/* Exposición */}
                <div className="mt-8 bg-Primary_Color_Light border border-gray px-4 py-4 rounded-lg w-1/4">
                    <div className='flex flex-col items-center'>
                        <p className="text-Secondary_Color text-lg font-semibold mb-2 border-b border-Secondary_Color">Exposición</p>
                        <p className="text-lg font-bold text-white">420</p> 
                    </div>
                </div>

                {/* Visualizaciones */}
                <div className="mt-8 bg-Primary_Color_Light border border-gray px-4 py-4 rounded-lg w-1/4">
                    <div className='flex flex-col items-center'>
                        <p className="text-Secondary_Color text-lg font-semibold mb-2 border-b border-Secondary_Color">Visualizaciones</p>
                        <p className="text-lg font-bold text-white">265</p> 
                    </div>
                </div>

                {/* Interesados */}
                <div className="mt-8 bg-Primary_Color_Light border border-gray px-4 py-4 rounded-lg w-1/4">
                    <div className='flex flex-col items-center'>
                        <p className="text-Secondary_Color text-lg font-semibold mb-2 border-b border-Secondary_Color">Interesados</p>
                        <p className="text-lg font-bold text-white">280</p>
                    </div>
                </div>
            </div>
            <div className=" mb-8 mt-8 w-full"> 
            
                <div className="flex justify-left space-x-8 bg-Primary_Color_Light p-4 rounded-t-lg border-b border-gray">
                    <button
                    className={`text-white text-lg font-semibold border-b-2 border-transparent hover:border-gray ${
                        selectedChart === 'Exposición' ? 'border-b-2 border-white hover:border-gray' : ''
                    } `}
                    onClick={() => selectChart('Exposición')}>
                    Exposición
                    </button>

                    <button
                    className={`text-white text-lg font-semibold border-b-2 border-transparent hover:border-gray ${
                        selectedChart === 'Visualización' ? 'border-b-2 border-white hover:border-gray' : ''
                    } `}
                    onClick={() => selectChart('Visualización')}>
                    Visualizaciones
                    </button>


                    <button
                    className={`text-white text-lg font-semibold border-b-2 border-transparent hover:border-gray ${
                        selectedChart === 'Interacción' ? 'border-b-2 border-white hover:border-gray' : ''
                    } `}
                    onClick={() => selectChart('Interacción')}>
                    Interesados
                    </button>
                   
                </div>
                <div className="bg-Primary_Color_Light p-8 rounded-b-lg h-full"> 
                    <div className="mt-8">
                    {renderChart()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Metricas;
